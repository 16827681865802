import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title?: string;
  description?: string;
  path?: string;
}

export default function SEO({ 
  title = 'HabitSense - Build Better Habits with AI',
  description = 'Track, analyze, and improve your daily habits with AI-powered insights and beautiful visualizations.',
  path = ''
}: SEOProps) {
  const url = `https://habitsense.ai${path}`;
  
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={url} />
      
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={url} />
      
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  );
}