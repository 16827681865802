import { format, eachDayOfInterval, subDays, startOfWeek, addDays, startOfMonth, endOfMonth } from 'date-fns';
import { Habit } from '../../types/habit';
import { useState } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import { habitApi, useHabits } from '../../contexts/HabitContext';
import { useUser } from '../../contexts/UserContext';

interface HeatmapProps {
  habit: Habit;
}

interface HeatmapProps {
  habit: Habit;
  date: Date;  // Add this prop
}

export default function Heatmap({ habit, date }: HeatmapProps) {
  const { state: userState } = useUser();
  const { dispatch } = useHabits();
  const { theme } = useTheme();
  const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

  // Calculate date range for the selected month
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);
  const dateInterval = eachDayOfInterval({ start: startDate, end: endDate });

  // Get completion intensity (0-4)
  const getIntensity = (date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    return habit.completions[dateStr] ? 4 : 0;
  };

  // Get color based on intensity and theme
  const getColor = (intensity: number) => {
    if (theme === 'dark') {
      switch (intensity) {
        case 0: return 'bg-gray-700 hover:bg-gray-600';
        case 1: return 'bg-emerald-900/70 hover:bg-emerald-800';
        case 2: return 'bg-emerald-700/80 hover:bg-emerald-600';
        case 3: return 'bg-emerald-600/90 hover:bg-emerald-500';
        case 4: return 'bg-emerald-500 hover:bg-emerald-400';
      }
    } else {
      switch (intensity) {
        case 0: return 'bg-gray-200 hover:bg-gray-300';
        case 1: return 'bg-emerald-100 hover:bg-emerald-200';
        case 2: return 'bg-emerald-200 hover:bg-emerald-300';
        case 3: return 'bg-emerald-300 hover:bg-emerald-400';
        case 4: return 'bg-emerald-400 hover:bg-emerald-500';
      }
    }
    return '';
  };

  const toggleHabit = async (date: Date) => {
    if (!userState.profile?.id) return;  // Add this check

    const dateStr = format(date, 'yyyy-MM-dd');
    const isCompleted = habit.completions[dateStr] ?? false;

    try {
      await habitApi.toggle(userState.profile.id, habit.id, dateStr, !isCompleted);
      dispatch({
        type: 'TOGGLE_COMPLETION',
        payload: {
          habitId: habit.id,
          date: dateStr,
          completed: !isCompleted,
        },
      });
    } catch (error) {
      console.error('Failed to toggle habit:', error);
    }
  };

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <span className="text-xl">{habit.emoji}</span>
          <h3 className="text-base font-semibold text-gray-900 dark:text-white">
            {habit.name}
          </h3>
        </div>
        
        <div className="relative bg-white/50 dark:bg-gray-800/50 rounded-xl p-4 shadow-sm">
          {hoveredDate && (
            <div className="absolute -top-8 left-1/2 transform -translate-x-1/2 
                          bg-gray-900 dark:bg-gray-700 text-white px-3 py-1.5 rounded-lg
                          text-sm whitespace-nowrap shadow-lg z-10">
              {format(hoveredDate, 'MMMM d, yyyy')}
            </div>
          )}
          
          <div className="grid grid-cols-[repeat(7,min-content)] gap-1 justify-center">
            {dateInterval.map((date) => {
              const intensity = getIntensity(date);
              
              return (
                <div
                  key={date.toISOString()}
                  className={`w-6 h-6 rounded-sm ${getColor(intensity)}
                            transition-all duration-200 ease-in-out
                            hover:scale-110 hover:shadow-lg
                            cursor-pointer`}
                  onMouseEnter={() => setHoveredDate(date)}
                  onMouseLeave={() => setHoveredDate(null)}
                  onClick={() => toggleHabit(date)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}