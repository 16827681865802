import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { userApi, useUser } from '../../contexts/UserContext';
import { Dialog } from '@headlessui/react';

export default function SignupForm() {
  const navigate = useNavigate();
  const { dispatch } = useUser();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    isPremium: false,
    agreedToTerms: false,
  });
  const [error, setError] = useState<string | null>(null);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);
  const [termsHtml, setTermsHtml] = useState<string>('');
  const [privacyHtml, setPrivacyHtml] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchLegalDocs = async () => {
      setIsLoading(true);
      try {
        const [termsResponse, privacyResponse] = await Promise.all([
          fetch('/terms_and_conditions.html'),
          fetch('/privacy_policy.html')
        ]);

        console.log(termsResponse);
        console.log(privacyResponse);
        
        const [terms, privacy] = await Promise.all([
          termsResponse.text(),
          privacyResponse.text()
        ]);

        setTermsHtml(terms);
        setPrivacyHtml(privacy);
      } catch (error) {
        console.error('Error loading legal documents:', error);
        setError('Failed to load legal documents');
      } finally {
        setIsLoading(false);
      }
    };

    fetchLegalDocs();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.agreedToTerms) {
      toast.error('You must agree to the Terms and Privacy Policy');
      return;
    }
    if (formData.password.length < 8) {
      toast.error('Password must be at least 8 characters long');
      return;
    }
    try {
      await userApi.create({
        email: formData.email,
        password: formData.password,
        name: formData.name,
        isPremium: formData.isPremium,
      });

      const user = await userApi.login(formData.email, formData.password);
      
      dispatch({
        type: 'LOGIN_SUCCESS',
        payload: {
          isAuthenticated: true,
          loading: false,
          error: null,
          name: user.name,
          profile: user,
          subscription: null,
        },
      });
      navigate('/dashboard');
    } catch (err: any) {
      toast.error("Failed to create account. You may already have an account with this email.");
    }
  };

  return (
    <div className="h-full w-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full backdrop-blur-sm bg-white/30 dark:bg-black/30 rounded-2xl p-8 
                    border border-white/20 shadow-xl">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-pink-600 
                       text-transparent bg-clip-text">
            Create Your Account
          </h2>
        </div>

        <form className="space-y-6" onSubmit={handleSubmit}>
          {error && <p className="text-red-500 text-sm">{error}</p>}
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Name
            </label>
            <input
              type="text"
              required
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 
                       dark:bg-gray-800 dark:text-gray-100 px-3 py-2 shadow-sm 
                       focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Email
            </label>
            <input
              type="email"
              required
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 
                       dark:bg-gray-800 dark:text-gray-100 px-3 py-2 shadow-sm 
                       focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            />
          </div>

          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
              Password
            </label>
            <input
              type="password"
              required
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              className="mt-1 block w-full rounded-md border border-gray-300 dark:border-gray-600 
                       dark:bg-gray-800 dark:text-gray-100 px-3 py-2 shadow-sm 
                       focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            />
          </div>

          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="terms"
                type="checkbox"
                checked={formData.agreedToTerms}
                onChange={(e) => setFormData({ ...formData, agreedToTerms: e.target.checked })}
                className="h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="terms" className="text-gray-700 dark:text-gray-300">
                I agree to the{' '}
                <button
                  type="button"
                  onClick={() => setIsTermsOpen(true)}
                  className="text-purple-600 hover:text-purple-500 dark:text-purple-400 dark:hover:text-purple-300 underline"
                >
                  Terms and Conditions
                </button>{' '}
                and{' '}
                <button
                  type="button"
                  onClick={() => setIsPrivacyOpen(true)}
                  className="text-purple-600 hover:text-purple-500 dark:text-purple-400 dark:hover:text-purple-300 underline"
                >
                  Privacy Policy
                </button>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 
                     text-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-200"
          >
            Sign Up
          </button>
        </form>

        <p className="mt-4 text-center text-sm text-gray-600 dark:text-gray-300">
          Already have an account?{' '}
          <Link to="/login" className="text-purple-600 dark:text-purple-400 hover:text-purple-500 dark:hover:text-purple-300">
            Login
          </Link>
        </p>

        <Dialog open={isTermsOpen} onClose={() => setIsTermsOpen(false)} className="relative z-50">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="mx-auto max-w-2xl w-full rounded-xl bg-white dark:bg-gray-900 max-h-[80vh] flex flex-col">
              <div className="sticky top-0 bg-white dark:bg-gray-800 p-6 pb-4 flex justify-between items-center border-b border-gray-200 dark:border-gray-700 rounded-t-xl">
                <Dialog.Title className="text-lg font-medium">Terms and Conditions</Dialog.Title>
                <button
                  type="button"
                  onClick={() => setIsTermsOpen(false)}
                  className="px-3 py-1 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                >
                  Close
                </button>
              </div>
              <div className="p-6 pt-0 overflow-y-auto flex-1">
                {isLoading ? (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
                  </div>
                ) : (
                  <div className="prose dark:prose-invert max-w-none" dangerouslySetInnerHTML={{ __html: termsHtml }} />
                )}
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>

        <Dialog open={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} className="relative z-50">
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="mx-auto max-w-2xl w-full rounded-xl bg-white dark:bg-gray-700 max-h-[80vh] flex flex-col">
              <div className="sticky top-0 bg-white dark:bg-gray-800 p-6 pb-4 flex justify-between items-center border-b border-gray-200 dark:border-gray-700 rounded-t-xl">
                <Dialog.Title className="text-lg font-medium">Privacy Policy</Dialog.Title>
                <button
                  type="button"
                  onClick={() => setIsPrivacyOpen(false)}
                  className="px-3 py-1 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                >
                  Close
                </button>
              </div>
              <div className="p-6 pt-0 overflow-y-auto flex-1">
                {isLoading ? (
                  <div className="flex justify-center py-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
                  </div>
                ) : (
                  <div className="prose dark:prose-invert max-w-none" dangerouslySetInnerHTML={{ __html: privacyHtml }} />
                )}
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </div>
  );
} 